<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-icon large> mdi-wan </v-icon>
        ผลิตภัณฑ์ที่ลูกค้าใช้งาน
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          color="secondary"
          single-line
          hide-details
        />
      </v-card-actions>
      <v-card-text>
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          @click="exportToExcel()"
        >
          <v-icon> mdi-microsoft-excel </v-icon>
          Export to Excel
        </v-btn>
        <v-data-table
          :headers="headers"
          :items="customerUseProducts"
          :search.sync="search"
          :options.sync="options"
          :footer-props="{ itemsPerPageOptions: [10, 50, 100, 1000] }"
          :loading="loading"
          loading-text="Loading..."
          :server-items-length="totalCustomerUseProducts"
          class="elevation-1"
        >
          <!-- <template v-slot:[`item.address`]="{ item }">
            <span>
              {{
                `${item.address} ${
                  item.subdistrict.district.province.id == 1 ? "" : "ต."
                }${item.subdistrict.name_in_thai} ${
                  item.subdistrict.district.province.id == 1 ? "" : "อ."
                }${item.subdistrict.district.name_in_thai} ${
                  item.subdistrict.district.province.id == 1 ? "" : "จังหวัด"
                }${item.subdistrict.district.province.name_in_thai} ${
                  item.subdistrict.zip_code
                }`
              }}
            </span>
          </template> -->
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              small
              color="secondary"
              class="ma-2"
              outlined
              :to="'/customer/' + item.customerUseService.customer.id + '/show'"
            >
              <v-icon> mdi-magnify </v-icon>
              Detail
            </v-btn>
            <!-- <v-btn
              small
              color="primary"
              class="ma-2"
              outlined
              :to="'/customer/' + item.id + '/edit'"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
              Edit
            </v-btn> -->
            <!-- <v-btn
            small
            color="primary"
            class="ma-2"
            outlined
            :to="'/customer/' + item.id + '/delete'"
          >
            <v-icon>
              mdi-delete
            </v-icon>
            Delete
          </v-btn> -->
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";

moment.locale("th");

export default {
  name: "CustomerUseProductList",
  props: {},
  data() {
    return {
      search: "",
      totalCustomerUseProducts: 0,
      options: {},
      customerUseProducts: [],
      loading: false,
      headers: [
        {
          text: "ประเภทผลิตภัณฑ์",
          value: "productCategory.name",
          sortable: false,
          align: "center",
        },
        {
          text: "Code",
          value: "code",
          sortable: false,
          align: "center",
        },
        {
          text: "ประเภทบริการ",
          value: "customerUseService.serviceCategory.name",
          sortable: false,
          align: "center",
        },
        {
          text: "ชื่อลูกค้า",
          value: "customerUseService.customer.name",
          sortable: false,
          align: "center",
        },
        {
          text: "ประเภทลูกค้า",
          value: "customerUseService.customer.customerCategory.name",
          sortable: false,
          align: "center",
        },
        {
          text: "ศูนย์บริการลูกค้า",
          value: "customerUseService.customer.officeCentre.name",
          sortable: false,
          align: "center",
        },
        {
          text: "ส่วนขายและบริการลูกค้า",
          value: "customerUseService.customer.officeCentre.officeSector.name",
          sortable: false,
          align: "center",
        },
        {
          text: "",
          value: "action",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromAPI();
      },
      deep: true,
    },
    search: {
      handler() {
        this.getDataFromAPI();
      },
      deep: true,
    },
  },
  created() {},
  methods: {
    formatDate(date) {
      return moment(date).format("LLL");
    },
    forceFileDownload(response, title) {
      console.log(title);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },
    getDataFromAPI() {
      this.loading = true;
      const queryParams = {
        params: {
          search: this.search,
          page: this.options.page,
          per_page: this.options.itemsPerPage,
        },
      };
      axios
        .get("/customer-use-product", queryParams)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.customerUseProducts = response.data.data;
          this.totalCustomerUseProducts = response.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    exportToExcel() {
      this.loading = true;
      const queryParams = {
        params: {
          search: this.search,
        },
        responseType: "arraybuffer",
      };
      axios
        .get("/export-to-excel/customer-use-product", queryParams)
        .then((response) => {
          // console.log(response);
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          // console.log(response.headers);
          link.download = "BCRM_ผลิตภัณฑที่ลูกค้าใช้งาน.xlsx";
          link.click();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
